import { SidebarStatusList } from "@prc-lib-core";

export enum SidebarId {
  Accommodation = 'accomodation',
  Project = 'projet',
  Informations = 'informations',
  Results = 'results',
}

export const SIDEBAR_STATUS_LIST: SidebarStatusList = [
  {
    id: SidebarId.Accommodation,
    title: 'Votre logement',
    description: 'Le lieu concerné par les travaux',
    isActive: false,
  },
  {
    id: SidebarId.Project,
    title: 'Votre projet',
    description: 'Les travaux que vous souhaitez réaliser',
    isActive: false,
  },
  {
    id: SidebarId.Informations,
    title: 'Vos informations',
    description: 'Les renseignements nécessaires au calcul de vos aides',
    isActive: false,
  },
  {
    id: SidebarId.Results,
    title: 'Votre devis et vos aides',
    description: '',
    isActive: false,
  }
]
